import { Button } from 'shared/Button'
import './Pagination.scss'
import { useMemo } from 'react'

function getInnerSlice(
  pagesMaxVisible: number,
  currentPage: number,
  pagesTotal: number
): [number, number] {
  // first and last page should always be visible
  const sliceBounds = {
    min: 1,
    max: pagesTotal - 1,
  }
  if (pagesTotal <= pagesMaxVisible) return [sliceBounds.min, sliceBounds.max + 1]

  const innerSliceSize = pagesMaxVisible - 2
  const innerSliceMidpoint = Math.round(innerSliceSize / 2)

  /* ⇽ [1] 2 3 … 11 ⇾ */
  if (currentPage < innerSliceMidpoint + 1)
    return [sliceBounds.min, sliceBounds.min + innerSliceSize]

  /* ⇽ 1 … 6 [7] 8 9 ⇾ */
  if (pagesTotal - currentPage - 2 < innerSliceMidpoint)
    return [sliceBounds.max - innerSliceSize, sliceBounds.max]

  /* ⇽ 1 … 6 [7] 8 … 10 ⇾ */
  return [currentPage - innerSliceMidpoint + 1, currentPage + innerSliceMidpoint]
}

type UsePaginationValuesConfig = {
  pagesTotal: number
  currentPage: number
  pagesMaxVisible: number
}
function usePaginationValues({
  pagesTotal,
  currentPage,
  pagesMaxVisible,
}: UsePaginationValuesConfig): (number | 'ELLIPSIS')[] {
  const fullArray = useMemo(() => new Array(pagesTotal).fill(0).map((v, i) => i), [pagesTotal])

  if (pagesTotal <= pagesMaxVisible) return new Array(pagesTotal).fill(0).map((v, i) => i)

  const [sliceStart, sliceEnd] = getInnerSlice(pagesMaxVisible, currentPage, pagesTotal)

  const innerArray: (number | 'ELLIPSIS')[] = fullArray.slice(sliceStart, sliceEnd)
  if (innerArray.at(0) !== 1) innerArray[0] = 'ELLIPSIS'
  if (innerArray.at(-1) !== fullArray.at(-2)) innerArray[innerArray.length - 1] = 'ELLIPSIS'

  const [first, last] = [fullArray.at(0), fullArray.at(-1)]
  if (first === undefined || last === undefined) throw new Error('First or last page is missing')
  const result = [first, ...innerArray, last]

  return result
}

type Props = {
  currentPageIndex: number
  pagesTotal: number
  pagesMaxVisible?: number
  requestPage: (pageIndex: number) => void
}
export function Pagination({
  currentPageIndex: currentPage,
  pagesTotal,
  requestPage,
  pagesMaxVisible = 9,
}: Props) {
  return (
    <div className="Pagination">
      <Button
        appearance="text"
        className="arrow-button"
        square
        disabled={currentPage === 0}
        onClick={() => requestPage(currentPage - 1)}
      >
        &lt;
      </Button>
      <InnerContent
        currentPage={currentPage}
        onRequestPage={requestPage}
        pagesTotal={pagesTotal}
        pagesMaxVisible={pagesMaxVisible}
      />

      <Button
        appearance="text"
        square
        className="arrow-button menu-button"
        disabled={currentPage >= pagesTotal - 1}
        onClick={() => requestPage(currentPage + 1)}
      >
        &gt;
      </Button>
    </div>
  )
}

type InnerContentProps = {
  pagesTotal: number
  currentPage: number
  pagesMaxVisible: number
  onRequestPage: (pageIndex: number) => void
}
function InnerContent({
  pagesTotal,
  currentPage,
  onRequestPage,
  pagesMaxVisible,
}: InnerContentProps) {
  const numbersArr = usePaginationValues({ currentPage, pagesTotal, pagesMaxVisible })
  if (pagesTotal === 0) {
    return (
      <Button appearance="text" square className="pag-button" disabled>
        1
      </Button>
    )
  }

  return (
    <>
      {numbersArr.map((value, index) => {
        if (value === 'ELLIPSIS')
          return (
            <div className="pagination-ellipsis" key={'...' + index}>
              ...
            </div>
          )
        return (
          <Button
            appearance={value === currentPage ? 'solid' : 'text'}
            square
            key={value}
            onClick={() => onRequestPage(value)}
          >
            {value + 1}
          </Button>
        )
      })}
    </>
  )
}
