import { AuthProviderProps } from 'react-oidc-context'

export const OAUTH_CONFIG = {
  authority: process.env.REACT_APP_OAUTH_AUTHORITY ?? '',
  client_id: process.env.REACT_APP_OAUTH_CLIENT_ID ?? '',
  client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET ?? '',
  redirect_uri: window.location.origin,
} satisfies AuthProviderProps
;(function () {
  if (OAUTH_CONFIG.authority === '') throw new Error('OAUTH: invalid authority')
  if (OAUTH_CONFIG.client_id === '') throw new Error('OAUTH: invalid client id')
})()
