import './NoteMedia.scss'

import { useMediaForNote } from 'core/api/notes'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import classNames from 'classnames'
import { AllHTMLAttributes } from 'react'
import { MediaUploadingStatus } from './MediaUploadingStatus/MediaUploadingStatus'
import { NoteSectionContext } from '../NoteSectionContext'
import { useAttachmentUploading } from './useAttachmentUploading.hook'
import { NoteMediaHeader } from './NoteMediaHeader/NoteMediaHeader'
import { NoteMediaGrid } from './NoteMediaGrid'
import { useNotifications } from 'shared/Notifications'

type NoteMediaProps = AllHTMLAttributes<HTMLDivElement>
export function NoteMedia({ className, ...props }: NoteMediaProps) {
  const { note, onNoteCreate, onNoteUpdate } = useContext(NoteSectionContext)
  const { sort, setSort, sortedMedia, mutateMedia, isLoading } = useSortedMediaForNote(note?.id)
  const update = async () => await Promise.all([onNoteUpdate(), mutateMedia()])
  const { showNotification } = useNotifications()

  const { handleFiles, uploadingList } = useAttachmentUploading({
    onUpdate: update,
    onNoteCreate,
    note,
  })

  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
    onDrop: (files) => {
      handleFiles(files)
      inputRef.current && (inputRef.current.value = '')
    },
    accept: { 'image/jpeg': ['.jpg', '.jpeg'], 'image/png': ['.png'] },
    noClick: true,
    onDropRejected: (files) => {
      if (files.length < 0) return
      files.forEach((f) =>
        showNotification({ text: `File ${f.file.name} rejected`, type: 'error' })
      )
      showNotification({ text: 'Only JPEG/PNG images are allowed', type: 'error' })
    },
  })

  useCapturingFilesFromDocument(handleFiles)

  return (
    <section className={classNames('NoteMedia', className)} {...props}>
      <div className="attachments-section__wrapper">
        <div
          className={classNames('attachments-section', isDragActive ? 'dragged-over' : '')}
          {...getRootProps()}
        >
          <NoteMediaHeader
            onBrowseClick={() => inputRef.current?.click()}
            sort={sort}
            setSort={setSort}
            sortingDisabled={sortedMedia.length === 0}
          />
          <MediaUploadingStatus uploadingList={uploadingList} />
          <NoteMediaGrid isLoading={isLoading} items={sortedMedia} onMediaUpdate={mutateMedia} />
          <input {...getInputProps()} />
          <div className="file-drop-overlay">Drop file here</div>
        </div>
      </div>
    </section>
  )
}

function useSortedMediaForNote(noteId?: string | null) {
  const mediaResponse = useMediaForNote(noteId)
  const [sort, setSort] = useState<'asc' | 'desc'>('asc')
  const sortedMedia = useMemo(() => {
    const result = [...(mediaResponse.data?.result ?? [])]
    return result.sort((a, b) => {
      const delta = new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime()
      return delta * (sort === 'asc' ? 1 : -1)
    })
  }, [mediaResponse.data?.result, sort])

  return {
    sortedMedia,
    isLoading: mediaResponse.isLoading,
    sort,
    setSort,
    mutateMedia: mediaResponse.mutate,
  }
}

function useCapturingFilesFromDocument(handleFiles: (files: (File | null)[]) => void) {
  const extractFiles = useCallback(
    (event: React.ClipboardEvent<HTMLDivElement> | ClipboardEvent): File[] => {
      event.preventDefault()
      if (event.clipboardData == null) return []

      const items = Array.from(event.clipboardData.items)
      const files: File[] = items
        .map((item) => item.getAsFile())
        .filter((file): file is File => !!file)
      return files
    },
    []
  )

  // catch paste event from document
  useEffect(() => {
    const handleDocumentPaste = (event: ClipboardEvent) => {
      console.log('event paste', event)
      handleFiles(extractFiles(event))
    }
    document.addEventListener('paste', handleDocumentPaste)
    return () => {
      document.removeEventListener('paste', handleDocumentPaste)
    }
  }, [extractFiles, handleFiles])
}
