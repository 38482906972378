import './CreateJournalNoteModal.scss'

import { useJournals } from 'core/api/journals'
import { JournalInfo, Note } from 'core/types'
import { useState } from 'react'
import { ModalController } from 'shared/Modal'
import { ModalLayout } from 'shared/ModalLayout'
import { NoteSection } from '../../components/NoteSection/NoteSection'
import { SimpleDropdown } from 'shared/Dropdown'
import { Button } from 'shared/Button'
import { useCallAndReport } from 'core/hooks/useDoAndReport'

import infoIco from 'assets/textures/info-blue.svg'
import {
  createJournalNote,
  editNote,
  getNote,
  markNoteAsCreated,
  moveJournalNoteToJournal,
} from 'core/api/notes'

type CreateJournalNoteModalProps = {
  controller: ModalController
  onUpdate: () => Promise<unknown>
}
export function CreateJournalNoteModal({ controller, onUpdate }: CreateJournalNoteModalProps) {
  const journals = useJournals()
  const [selectedJournal, setSelectedJournal] = useState<JournalInfo | null>(null)
  const [createdNote, setCreatedNote] = useState<Note | null>(null)
  const [noteText, setNoteText] = useState('')

  const createNote = async () => {
    if (!selectedJournal) throw new Error('No journal selected')
    const response = await createJournalNote(selectedJournal.id)
    const note = response.data.result
    setCreatedNote(note)
    return note
  }

  const onNoteSectionUpdate = async () => {
    // await controller.data.onUpdate()
    console.log('onNoteSectionUpdate')
    if (!createdNote) return
    const data = await getNote(createdNote.id)
    console.log('data', data)
  }

  const { callAndReport, isLoading } = useCallAndReport()

  const finalize = async () => {
    if (!selectedJournal) throw new Error('No journal selected')
    await callAndReport(
      async () => {
        let note = createdNote
        if (!createdNote) {
          note = await createNote()
          setCreatedNote(note)
        }
        if (!note) throw new Error('Note creation failed')
        if (note.journalId !== selectedJournal.id) {
          await moveJournalNoteToJournal(note.id, selectedJournal.id)
        }
        await editNote(noteText, note.id)
        await markNoteAsCreated(note.id)
        await onUpdate()
      },
      { OK: 'Note created successfully' }
    )

    controller.close()
  }

  return (
    <ModalLayout className="CreateJournalNoteModal">
      <ModalLayout.Heading>Add new journal note</ModalLayout.Heading>

      <div className="infobox">
        <div className="ico">
          <img src={infoIco} alt="" />
        </div>
        <div className="text">
          Want to create a trade note? Go to Trades History tab, select a trade and create a note
          there.
        </div>
      </div>

      <ModalLayout.FormControl label="Journal">
        <SimpleDropdown
          onChange={setSelectedJournal}
          value={selectedJournal}
          getVisualValue={(v) => v?.journalName}
        >
          {journals.data?.map((journal) => (
            <SimpleDropdown.Item key={journal.id} value={journal}>
              {journal.journalName}
            </SimpleDropdown.Item>
          ))}
        </SimpleDropdown>
      </ModalLayout.FormControl>
      <NoteSection
        altNoteTextArea={
          <textarea
            className="input"
            placeholder="Type your note here..."
            rows={7}
            value={noteText}
            onChange={(e) => setNoteText(e.target.value)}
          />
        }
        editable={true}
        note={createdNote ?? undefined}
        disabled={selectedJournal === null}
        onNoteUpdate={onNoteSectionUpdate}
        onNoteCreate={createNote}
      />
      <ModalLayout.Buttons>
        <Button onClick={() => controller.close()}>Cancel</Button>
        <Button color="primary" onClick={() => finalize()} isLoading={isLoading}>
          Create
        </Button>
      </ModalLayout.Buttons>
    </ModalLayout>
  )
}
