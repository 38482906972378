import { JournalInfo } from 'core/types'
import { useMemo } from 'react'
import AddEditNoteModal from '../modals/AddEditNoteModal'
import { SelectJournalDialog } from '../modals/MoveToJournalModal/MoveToJournalModal'
import { Modal, useModal } from 'shared/Modal'
import { ModalLayout } from 'shared/ModalLayout'
import { Button } from 'shared/Button'
import { TradesTableSectionContextData } from './models'
import { TradesTableTabContext } from './TradesTableTabContext'
import { useTradesTableApi, useTradesTableHeaders } from './hooks'
import { TableConfigModal } from '../modals/TableConfigModal/TableConfigModal'
import { useJournalsApi } from 'core/contexts/JournalsApiContext/JournalsApiContext'
import { useFilteredTrades } from '../hooks'
import useUpdateHomePageData from 'pages/HomePage/hooks/useUpdateHomePageData'
import { useRecoilState } from 'recoil'
import { tradesTableState } from 'core/store/tradesTable'

type TradesTableTabContextProps = {
  children: React.ReactNode
}
export function TradesTableTabContextProvider({ children }: Readonly<TradesTableTabContextProps>) {
  const [{ selectedTradeId }, setState] = useRecoilState(tradesTableState)
  const tradesResponse = useFilteredTrades()
  const selectedTrade = useMemo(() => {
    if (!tradesResponse.data) return null
    const result = tradesResponse.data.result.find((trade) => trade.id === selectedTradeId)
    if (!result) return null
    return result
  }, [selectedTradeId, tradesResponse])

  const { deleteTrade, isLoading, moveTrade } = useTradesTableApi()
  const { columns, isColumSelectedMap, setColumnsKeys } = useTradesTableHeaders()

  const { updateJournals: onUpdateJournals } = useJournalsApi()
  const updateHomePageData = useUpdateHomePageData()

  const addEditNoteModal = useModal()
  const confirmDeleteModal = useModal()
  const selectJournalModal = useModal()
  const tableConfigModal = useModal()

  const providerValue = useMemo(() => {
    return {
      selectedTrade,
      openAddNoteModal: (item) => {
        item && setState((state) => ({ ...state, selectedTradeId: item.id }))
        addEditNoteModal.open()
      },
      openMoveTradeToModal: (item) => {
        setState((state) => ({ ...state, selectedTradeId: item.id }))
        selectJournalModal.open()
      },
      openConfirmDeleteTradeModal: (item) => {
        setState((state) => ({ ...state, selectedTradeId: item.id }))
        confirmDeleteModal.open()
      },
      openTableConfigModal: () => {
        tableConfigModal.open()
      },
      columns,
      isColumSelectedMap,
      setColumnsKeys,
    } satisfies TradesTableSectionContextData
  }, [
    selectedTrade,
    columns,
    isColumSelectedMap,
    setColumnsKeys,
    setState,
    addEditNoteModal,
    selectJournalModal,
    confirmDeleteModal,
    tableConfigModal,
  ])

  const deleteSelectedTrade = async () => {
    if (selectedTradeId == null) return
    try {
      await deleteTrade(selectedTradeId)
    } catch (e) {}

    updateHomePageData()
    onUpdateJournals()
    confirmDeleteModal.close()
  }

  const moveSelectedTrade = async (journal: JournalInfo) => {
    if (selectedTradeId == null) return
    try {
      await moveTrade(selectedTradeId, journal.id, journal.journalName)
    } catch (e) {}
    updateHomePageData()
    onUpdateJournals()
    selectJournalModal.close()
  }

  return (
    <TradesTableTabContext.Provider value={providerValue}>
      {children}
      <Modal controller={selectJournalModal}>
        <SelectJournalDialog
          trade={selectedTrade}
          onSelectJournal={(journal) => moveSelectedTrade(journal)}
          onClose={() => selectJournalModal.close()}
          isLoading={isLoading}
        />
      </Modal>
      <Modal controller={addEditNoteModal}>
        <AddEditNoteModal onClose={() => addEditNoteModal.close()} />
      </Modal>
      <Modal controller={confirmDeleteModal}>
        <ModalLayout>
          <ModalLayout.CloseButton onClick={() => confirmDeleteModal.close()} />
          <ModalLayout.Heading>Delete trade</ModalLayout.Heading>
          <ModalLayout.Description>
            Once removed, this action cannot be undone. The user will lose access to the trade and
            any associated data.
          </ModalLayout.Description>
          <ModalLayout.Buttons>
            <Button onClick={() => confirmDeleteModal.close()}>Cancel</Button>
            <Button color="error" isLoading={isLoading} onClick={() => deleteSelectedTrade()}>
              Delete
            </Button>
          </ModalLayout.Buttons>
        </ModalLayout>
      </Modal>
      <TableConfigModal controller={tableConfigModal} />
    </TradesTableTabContext.Provider>
  )
}
