import './NoteCard.scss'

import { JournalInfo, Note } from 'core/types'
import { MediaRow } from './MediaRow/MediaRow'
import TextDate from 'shared/TextDate'
import { TradeInfo } from './TradeInfo/TradeInfo'
import { AllHTMLAttributes } from 'react'
import classNames from 'classnames'
import Menu from 'shared/Menu'
import { Button } from 'shared/Button'
import Icon from 'shared/Icon'

type NoteItemProps = {
  editable?: boolean
  journal?: JournalInfo
  note: Note
  onDelete: () => void
} & AllHTMLAttributes<HTMLDivElement>
export function NoteItem({
  journal,
  note,
  onDelete,
  className,
  editable,
  ...props
}: NoteItemProps) {
  return (
    <>
      <div
        className={classNames('NoteItem', className, { draft: note.status === 'DRAFT' })}
        {...props}
      >
        <div className="header">
          <div className="journal-name text-big-bold">
            {journal?.journalName ?? 'Unknown journal'}
          </div>
          <div className="creation-date color-secondary-l3">
            <TextDate date={new Date(note.creationDate)} />
            {editable && (
              <div onClick={(e) => e.stopPropagation()}>
                <Menu
                  trigger={
                    <Button appearance="menu-item" size="small" square>
                      <Icon name="DotsVertical" />
                    </Button>
                  }
                >
                  <Menu.Item onClick={() => onDelete()}>Remove</Menu.Item>
                </Menu>
              </div>
            )}
          </div>
        </div>
        <TradeInfo trade={note.relatedTrade} />
        <div className="content">
          <div className="text">{note.noteText}</div>
        </div>
        <MediaRow noteId={note.id} mediaCount={note.mediaCount} />
      </div>
    </>
  )
}
