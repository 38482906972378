import { AxiosError, AxiosProgressEvent } from 'axios'
import { addMediaToNote } from 'core/api/notes'
import { Note } from 'core/types'
import { useMemo, useState } from 'react'
import { useNotifications } from 'shared/Notifications'

const FILE_PARAMS = {
  maxSize: 5 * 1024 * 1024,
  allowedFormats: ['image/jpeg', 'image/png'],
}
export type UploadingItem = {
  file: File
  startTimestamp: number
  progress?: number | null
  id: string
}
type UseAttachmentUploadingConfig = {
  onUpdate: () => Promise<unknown>
  onNoteCreate: () => Promise<Note>
  note?: Note
}
type UseAttachmentUploadingReturn = {
  uploadingList: UploadingItem[]
  handleFiles: (files: (File | null)[]) => void
}

export function useAttachmentUploading({
  onUpdate,
  onNoteCreate,
  note,
}: UseAttachmentUploadingConfig): UseAttachmentUploadingReturn {
  const { addFileToMap, updateProgress, removeFileFromMap, uploadingList } = useUploadingMap()
  const { showNotification } = useNotifications()

  const uploadFile = async (file: File) => {
    const uploadingEntryId = crypto.randomUUID()

    try {
      let currentNote: Note = note ? note : await onNoteCreate()

      addFileToMap(file, uploadingEntryId)
      const onProgressUpdate = (ev: AxiosProgressEvent) =>
        updateProgress(uploadingEntryId, ev.progress ? ev.progress * 100 : null)

      await addMediaToNote(currentNote.id, file, onProgressUpdate)
      await onUpdate()
    } catch (err) {
      console.error(err)
      let text = 'Failed to upload image'
      if (err instanceof AxiosError && err.response?.data?.message) {
        text = err.response.data.message
      }
      showNotification({ text, type: 'error' })
    } finally {
      removeFileFromMap(uploadingEntryId)
    }
  }

  const handleFiles = (files: (File | null)[]) => {
    console.log('files', files)
    files.forEach((file) => {
      if (!file) return
      if (file.size > FILE_PARAMS.maxSize) {
        return showNotification({
          text: 'File size should be less than 5MB',
          type: 'error',
        })
      }
      if (!FILE_PARAMS.allowedFormats.includes(file.type)) {
        return showNotification({
          text: 'File should be an JPG or PNG image',
          type: 'error',
        })
      }
      uploadFile(file)
    })
  }

  return { uploadingList, handleFiles }
}

function useUploadingMap() {
  const [uploadingMap, setUploadingMap] = useState<{ [id: string]: UploadingItem }>({})
  const uploadingList = useMemo(
    () => Object.values(uploadingMap).sort((a, b) => a.startTimestamp - b.startTimestamp),
    [uploadingMap]
  )

  const addFileToMap = (file: File, id: string) => {
    if (uploadingMap[id]) throw new Error('File already exists in uploading map')
    const timestamp = Date.now()
    const entry: UploadingItem = {
      file,
      id,
      startTimestamp: timestamp,
      progress: undefined,
    }

    setUploadingMap((map) => ({ ...map, [id]: entry }))
  }

  /** Update progress value of file uploading */
  const updateProgress = (uploadingEntryId: string, progress?: number | null) => {
    setUploadingMap((oldMap) => ({
      ...oldMap,
      [uploadingEntryId]: { ...oldMap[uploadingEntryId], progress } satisfies UploadingItem,
    }))
  }

  /** Remove file from loading map to hide related loader indicator */
  const removeFileFromMap = (uploadingEntryId: string) => {
    setUploadingMap((map) => {
      const { [uploadingEntryId]: _, ...rest } = map
      return rest
    })
  }

  return { uploadingList, addFileToMap, updateProgress, removeFileFromMap }
}
