import { JournalInfo, Note } from 'core/types'
import { ModalController } from 'shared/Modal'
import ModalWindowLayout from 'shared/ModalWindowLayout'
import { JournalInfoGrid } from '../../components/JournalInfoGridFull/JournalInfoGrid'
import { NoteSection } from '../../components/NoteSection/NoteSection'
import { useCallback, useState } from 'react'
import { getNote } from 'core/api/notes'
import { Button } from 'shared/Button'

type JournalNoteModalProps = {
  controller: ModalController<
    void,
    {
      journal: JournalInfo
      note?: Note
      editable: boolean
    }
  >
  onUpdate: () => Promise<unknown>
}
export function JournalNoteModal({ controller, onUpdate }: JournalNoteModalProps) {
  if (!controller.data) throw new Error('JournalNoteModal: no data provided')
  const { journal, note } = controller.data

  const [updatedNote, setUpdatedNote] = useState<Note | null>(null)

  const onUpdated = async () => {
    if (!note) throw new Error('No note provided')
    const response = await getNote(note.id)
    setUpdatedNote(response.data.result)
    return await onUpdate()
  }

  const createNote: () => Promise<Note> = useCallback(async () => {
    throw new Error('Not implemented')
  }, [])

  return (
    <ModalWindowLayout>
      <ModalWindowLayout.Header label="Journal Note" onClose={() => controller.close()}>
        Journal Note
      </ModalWindowLayout.Header>
      <ModalWindowLayout.Content>
        <JournalInfoGrid journal={journal} />
        <NoteSection
          note={updatedNote ?? note}
          onNoteCreate={createNote}
          onNoteUpdate={onUpdated}
          editable={controller.data.editable}
        />
      </ModalWindowLayout.Content>
      <ModalWindowLayout.Footer>
        <Button onClick={() => controller.close()}>OK</Button>
      </ModalWindowLayout.Footer>
    </ModalWindowLayout>
  )
}
