import { Note } from 'core/types'
import { createContext, useMemo } from 'react'

type NoteSectionContextType = {
  note?: Note
  editable?: boolean
  onNoteCreate: () => Promise<Note>
  onNoteUpdate: () => Promise<unknown>
}
export const NoteSectionContext = createContext<NoteSectionContextType>({
  onNoteCreate: async () => {
    throw new Error('NoteSectionContextProvider not found')
  },
  onNoteUpdate: async () => {
    throw new Error('NoteSectionContextProvider not found')
  },
})

type NoteSectionContextProviderProps = {
  children: React.ReactNode
  note?: Note
  editable?: boolean
  onNoteCreate: () => Promise<Note>
  onNoteUpdate: () => Promise<unknown>
}
export function NoteSectionContextProvider({
  children,
  onNoteCreate,
  onNoteUpdate,
  editable,
  note,
}: NoteSectionContextProviderProps) {
  const value = useMemo(
    () => ({ note, onNoteCreate, onNoteUpdate, editable }),
    [note, onNoteCreate, onNoteUpdate, editable]
  )

  return <NoteSectionContext.Provider value={value}>{children}</NoteSectionContext.Provider>
}
