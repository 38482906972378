import { useCallback, useContext, useMemo } from 'react'
import { NoteSectionContext } from '../NoteSectionContext'
import { Modal, useModal } from 'shared/Modal'
import { useCallAndReport } from 'core/hooks/useDoAndReport'
import { deleteMediaForNote } from 'core/api/notes'
import MediaEntry from 'shared/MediaEntry'
import { ModalLayout } from 'shared/ModalLayout'
import { Button } from 'shared/Button'
import SkeletonBlock from 'shared/SkeletonBlock'
import { MediaItem } from 'core/types'

type MediaGridProps = {
  items: MediaItem[]
  isLoading: boolean
  onMediaUpdate: () => Promise<unknown>
}
export function NoteMediaGrid({ items, isLoading: isMediaLoading, onMediaUpdate }: MediaGridProps) {
  const { note, editable } = useContext(NoteSectionContext)
  const placeholderArr = useMemo(
    () => Array.from({ length: note?.mediaCount ?? 0 }, (_, i) => i),
    [note]
  )
  const confirmDeleteModal = useModal<boolean>()
  const { callAndReport, isLoading } = useCallAndReport()
  const deleteMedia = useCallback(
    async (mediaId: string) => {
      const result = await confirmDeleteModal.open()
      if (result !== true) return

      console.log('Deleting media', mediaId)
      if (!note) throw new Error('Note is not defined')
      await callAndReport(() => deleteMediaForNote(note.id, mediaId), {
        OK: 'Media deleted',
        DEFAULT_ERR: 'Failed to delete media',
      })
      await onMediaUpdate()
      confirmDeleteModal.close()
    },
    [callAndReport, confirmDeleteModal, note, onMediaUpdate]
  )

  if (!note) return null

  return (
    <>
      <div className="MediaGrid">
        {items.map((item) => (
          <MediaEntry
            key={item.key}
            mediaItem={item}
            hasDeleteButton={editable}
            onDelete={() => deleteMedia(item.key)}
          />
        ))}

        {isMediaLoading && placeholderArr.map((p) => <SkeletonBlock key={p} />)}
        <Modal controller={confirmDeleteModal}>
          <ModalLayout>
            <ModalLayout.Heading>Are you sure?</ModalLayout.Heading>
            <ModalLayout.Description>
              Confirm if you want to remove this attachment. This action cannot be undone
            </ModalLayout.Description>
            <ModalLayout.Buttons>
              <Button onClick={() => confirmDeleteModal.close(false)}>Cancel</Button>
              <Button
                isLoading={isLoading}
                color="error"
                onClick={() => confirmDeleteModal.resolveWithoutClosing(true)}
              >
                Delete
              </Button>
            </ModalLayout.Buttons>
          </ModalLayout>
        </Modal>
      </div>
      {!items.length && !isMediaLoading && <div className="empty-state">No media attached</div>}
    </>
  )
}
