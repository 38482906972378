import './TradesTableTab.scss'
import { Card } from 'shared/Card/Card'
import { Button } from 'shared/Button'
import Icon from 'shared/Icon'
import { TradesTable } from './components/TradesTable/TradesTable'
import { useCallback, useContext, useMemo, useState } from 'react'
import Menu from 'shared/Menu'
import { useSelectedJournal } from '../../components/Filters/useSelectedJournal'
import api from 'core/api/apiClient'
import { downloadBlob, filtersToQueryParams } from 'core/utils'
import { TradesTableTabContext, TradesTableTabContextProvider } from './TradesTableTabContext'
import { useRecoilState } from 'recoil'
import { filterState } from 'core/store'
import { tradesTableState } from 'core/store/tradesTable'
import { useFilteredTrades } from './hooks'
import Filters from 'pages/HomePage/components/Filters'
import { Modal, useModal } from 'shared/Modal'
import { Note, TradeItem } from 'core/types'
import { TradeNoteModal } from '../NotesTab/modals/TradeNoteModal/TradeNoteModal'
import Pagination from 'shared/Pagination'
import { useUserInfo } from 'core/api/common'
import useJournalsMap from 'core/hooks/useJournalsMap'

function useTradesExport() {
  const [loading, setLoading] = useState(false)
  const [filters] = useRecoilState(filterState)
  const selectedJournal = useSelectedJournal()

  const fileName = useMemo(() => {
    if (selectedJournal === null) return `All my trades`
    return `${selectedJournal.journalName} trades`
  }, [selectedJournal])

  const exportAsCsv = useCallback(async () => {
    setLoading(true)
    try {
      const blob: Blob = (await api.trade.exportAll.csv(filtersToQueryParams(filters))).data
      downloadBlob(blob, fileName + '.xlsx')
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }, [fileName, filters])

  const exportAsXlsx = useCallback(async () => {
    setLoading(true)
    try {
      const blob = (await api.trade.exportAll.excel(filtersToQueryParams(filters))).data
      downloadBlob(blob, fileName + '.xlsx')
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }, [fileName, filters])

  return { exportAsCsv, exportAsXlsx, loading }
}

function useTradeNoteModal() {
  const userInfo = useUserInfo()
  const journalsMap = useJournalsMap()
  const tradeNoteModal = useModal<void, { trade: TradeItem; note?: Note; editable: boolean }>()
  const openTradeNoteModel = useCallback(
    (trade: TradeItem) => {
      const journal = journalsMap.get(trade.journalId)
      if (!journal) throw new Error('Journal not found. Cannot open note modal')
      const editable = journal.owner.userEmail === userInfo.data?.userEmail
      tradeNoteModal.open({ trade, editable, note: trade.note ?? undefined })
    },
    [journalsMap, tradeNoteModal, userInfo.data?.userEmail]
  )

  return { tradeNoteModal, openTradeNoteModel }
}

export function TradesTableTabRaw() {
  const [{ page }, setTradesState] = useRecoilState(tradesTableState)
  const { openTableConfigModal } = useContext(TradesTableTabContext)
  const tradesResponse = useFilteredTrades()
  if (page !== 0 && tradesResponse.data && tradesResponse.data.result.length === 0) {
    setTimeout(() => setTradesState((st) => ({ ...st, page: 0 })))
  }
  const { exportAsCsv, exportAsXlsx, loading } = useTradesExport()

  const { tradeNoteModal, openTradeNoteModel } = useTradeNoteModal()

  if (!tradesResponse.data) return <Card className="TradesTableSection loading"></Card>

  return (
    <>
      <div className="TradesTableSection">
        <Filters />
        <Card>
          <div className="header text-normal-regular">
            Trade history
            <div className="buttons">
              <Menu
                trigger={
                  <Button size="small" isLoading={loading}>
                    <Icon name="Download" width={16} height={16} />
                    Export
                  </Button>
                }
              >
                <Menu.Item onClick={() => exportAsCsv()}>as .csv</Menu.Item>
                <Menu.Item onClick={() => exportAsXlsx()}>as .xlsx</Menu.Item>
              </Menu>

              <Button size="small" square onClick={() => openTableConfigModal()}>
                <Icon name="Settings" />
              </Button>
            </div>
          </div>
          <TradesTable
            data={tradesResponse.data}
            isLoading={tradesResponse.isLoading}
            onOpenTradeInfo={openTradeNoteModel}
          />
          <Pagination
            pagesTotal={tradesResponse.data?.pageInfo.totalPages ?? 0}
            currentPageIndex={page}
            requestPage={(page) => setTradesState((st) => ({ ...st, page }))}
          />
        </Card>
      </div>
      <Modal controller={tradeNoteModal}>
        <TradeNoteModal controller={tradeNoteModal} onUpdate={tradesResponse.mutate} />
      </Modal>
    </>
  )
}

function TradesTableTab() {
  return (
    <TradesTableTabContextProvider>
      <TradesTableTabRaw />
    </TradesTableTabContextProvider>
  )
}

export { TradesTableTab }
