import './PageLayoutHeader.scss'
import imgLogo from 'assets/trading-journal-logo.svg'
import { PageLayoutCustomHeader } from './PageLayoutCustomHeader'
import { Button } from 'shared/Button'
import Icon from 'shared/Icon'
import { Modal, ModalController, useModal } from 'shared/Modal'
import { useUserInfo } from 'core/api/common'
import Menu from 'shared/Menu'
import { useAuth } from 'react-oidc-context'

export function PageLayoutHeader() {
  const { data } = useUserInfo()
  const auth = useAuth()

  const signOut = async () => {
    await auth.signoutPopup()
    window.location.reload()
  }

  const sideMenuModal = useModal()

  return (
    <PageLayoutCustomHeader className="PageLayoutHeader">
      <div className="PageLayoutHeader__logo">
        <img src={imgLogo} alt="Bookmap" />
        <h1>Trading journal</h1>
      </div>
      <nav className="PageLayoutHeader__content">
        <a
          href="https://bookmap.com/knowledgebase/docs/Addons-Trading-Journal"
          target="_blank"
          rel="noreferrer"
          className="desktop-only"
        >
          <Button appearance="link">Quick guide</Button>
        </a>
        <a
          href="https://bookmap.com/support/"
          target="_blank"
          rel="noreferrer"
          className="desktop-only"
        >
          <Button appearance="link">Contact support team</Button>
        </a>
        <a
          href="https://forms.gle/d33URJfpN3w1yDi47"
          target="_blank"
          rel="noreferrer"
          className="desktop-only"
        >
          <Button appearance="solid" color="primary">
            Share your feedback
          </Button>
        </a>
        <div className="divider desktop-only"></div>
        <Menu
          trigger={
            <Button appearance="text" size="small" className="desktop-only" disablePadding>
              {data ? (
                <>
                  {data.userEmail}
                  <Icon name="ArrowSimple" rotate="270" />
                </>
              ) : (
                'Loading...'
              )}
            </Button>
          }
        >
          <Button onClick={() => signOut()}>
            <Icon name="LogOut" />
            Log out
          </Button>
        </Menu>
        <Button
          className="menu-button mobile-only"
          appearance="menu-item"
          square
          onClick={() => sideMenuModal.open()}
        >
          <Icon name="MenuHamburger" />
        </Button>
        <SideMenu controller={sideMenuModal} onLogOut={() => signOut()} />
      </nav>
    </PageLayoutCustomHeader>
  )
}

type SideMenuProps = {
  controller: ModalController
  onLogOut: () => void
}
function SideMenu({ controller, onLogOut }: Readonly<SideMenuProps>) {
  const { data } = useUserInfo()

  return (
    <Modal controller={controller} position="end">
      <div className="PageLayoutHeader__SideMenu">
        <div className="PageLayoutHeader__SideMenu__header">
          <Button appearance="menu-item" onClick={() => controller.close()} square>
            <Icon name="Cross" />
          </Button>
          <h4>Menu</h4>
        </div>
        <div className="PageLayoutHeader__SideMenu__content">
          <div className="auth">
            <div className="email text-big-bold"> {data?.userEmail}</div>
            <Button appearance="outline" onClick={onLogOut}>
              Log out
            </Button>
          </div>
          <nav>
            <a
              href="https://bookmap.com/knowledgebase/docs/Addons-Trading-Journal"
              target="_blank"
              rel="noreferrer"
              className="desktop-only"
            >
              <Button appearance="link">Quick guide</Button>
            </a>
            <a
              href="https://bookmap.com/support/"
              target="_blank"
              rel="noreferrer"
              className="desktop-only"
            >
              <Button appearance="link">Contact support team</Button>
            </a>
            <a
              href="https://forms.gle/d33URJfpN3w1yDi47"
              target="_blank"
              rel="noreferrer"
              className="desktop-only"
            >
              <Button appearance="solid" color="primary">
                Share your feedback
              </Button>
            </a>
          </nav>
        </div>
      </div>
    </Modal>
  )
}
