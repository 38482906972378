import { JournalInfo, TradeItem, TradesResponse } from 'core/types'
import './TradesTable.scss'

import Table from 'shared/Table'
import { useContext, useMemo } from 'react'
import { useJournals } from 'core/api/journals'
import { TradesTableTabContext } from '../../TradesTableTabContext'
import { TradesTableDynamicRow } from './TradesTableDynamicRow'
import { TABLE_PAGE_SIZE } from '../../TradesTableTabContext/constants'
import { useSelectedJournalIsLocked } from '../../hooks'
import { useRecoilState } from 'recoil'
import { filterState } from 'core/store'
import { tradesTableState } from 'core/store/tradesTable'

type TradesTableProps = {
  data: TradesResponse
  isLoading: boolean
  onOpenTradeInfo: (item: TradeItem) => void
}
export function TradesTable({ data, isLoading, onOpenTradeInfo }: Readonly<TradesTableProps>) {
  const [{ sort, order }, setState] = useRecoilState(tradesTableState)
  const { columns } = useContext(TradesTableTabContext)

  const sortBy = (key: keyof TradeItem) => {
    console.log('sort key', key)
    if (sort === key) {
      setState((st) => ({
        ...st,
        order: order === 'asc' ? 'desc' : 'asc',
      }))
    } else {
      setState((st) => ({
        ...st,
        sort: key,
        order: 'asc',
      }))
    }
  }

  const [filters] = useRecoilState(filterState)
  const journals = useJournals()
  const journalsIdMap: Record<string, JournalInfo> = useMemo(() => {
    if (!journals.data) return {}
    return Object.fromEntries(journals.data.map((j) => [j.id, j]))
  }, [journals])

  const isEmpty = data?.pageInfo.totalElements === 0

  const selectedJournalIsLocked = useSelectedJournalIsLocked()

  return (
    <Table className="TradesTable" tableNeighbor={isEmpty && <EmptyState />}>
      <Table.Header>
        {filters.journalId === null && <Table.HeaderCell>Journal</Table.HeaderCell>}
        {columns.map((header) => (
          <Table.HeaderCell<TradeItem>
            sortableKey={header.sortableKey}
            currentSortableKey={sort}
            sortableDirection={order}
            onSort={sortBy}
            tooltipText={header.tooltip}
            isLoading={isLoading}
            key={header.sortableKey}
          >
            {header.name}
          </Table.HeaderCell>
        ))}
        <Table.HeaderCell<TradeItem>>Note</Table.HeaderCell>
        {!selectedJournalIsLocked && <Table.HeaderCell.Empty />}
      </Table.Header>
      <Table.Body>
        {!isEmpty &&
          data.result.map((item) => (
            <TradesTableDynamicRow
              onClick={() => onOpenTradeInfo(item)}
              item={item}
              key={item.id}
              journalsIdMap={journalsIdMap}
            />
          ))}
      </Table.Body>
    </Table>
  )
}

function EmptyState() {
  return (
    <div className="EmptyState" style={{ height: 48 * TABLE_PAGE_SIZE + 'px' }}>
      <h2>No trades</h2>
      <p>
        It looks like there are no results that match your search criteria. Please, try another one
      </p>
    </div>
  )
}
