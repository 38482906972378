import App from 'App'
import { Home } from 'pages/HomePage/HomePage'
import DashboardTab from 'pages/HomePage/tabs/DashboardTab'
import { NotesTab } from 'pages/HomePage/tabs/NotesTab/NotesTab'
import { TradesTableTab } from 'pages/HomePage/tabs/TradesTableTab/TradesTableTab'
import { Login } from 'pages/Login/Login'
import { ManageJournalsPage } from 'pages/ManageJournalsPage/ManageJournalsPage'
import MetricsPage from 'pages/MetricsPage'
import { FC } from 'react'
import { useAuth } from 'react-oidc-context'
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom'

function useRedirectPath() {
  const redirectPath = localStorage.getItem('redirectPath')
  if (redirectPath) localStorage.removeItem('redirectPath')
  const rememberRedirectPath = () => localStorage.setItem('redirectPath', window.location.pathname)
  return { redirectPath, rememberRedirectPath }
}

const ProtectedRoute: FC<{ children?: React.ReactElement }> = ({ children }) => {
  const auth = useAuth()
  const { redirectPath, rememberRedirectPath } = useRedirectPath()

  if (auth.isLoading) return <Login /> // Login page will read from auth and show loader
  if (!auth.isAuthenticated) {
    /* User wanted to go somewhere, but they need to log in, so let's remember path */
    rememberRedirectPath()
    return <Navigate to="/login" replace />
  }
  /* User logged in, so let them continue the journey */
  if (redirectPath) return <Navigate to={redirectPath} replace />

  return <Outlet />
}

export const appRouter = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: 'login',
        element: <Login />,
      },
      {
        element: <ProtectedRoute />,
        children: [
          {
            path: '',
            element: <Navigate to="/home" replace />,
          },
          {
            path: 'manage-journals',
            element: <ManageJournalsPage />,
          },
          {
            path: 'metrics',
            element: <MetricsPage />,
          },
          {
            path: 'home',
            element: <Home />,
            children: [
              {
                path: 'dashboard',
                element: <DashboardTab />,
              },
              {
                path: 'trades-history',
                element: <TradesTableTab />,
              },
              {
                path: 'notes',
                element: <NotesTab />,
              },
              {
                path: '',
                element: <Navigate to="/home/dashboard" replace />,
              },
            ],
          },
        ],
      },
    ],
  },
])
