import { JournalFilterDropdown } from 'pages/HomePage/components/Filters/Filters'
import './NotesFilters.scss'
import { useState } from 'react'
import { JournalInfo } from 'core/types'
import { SimpleDropdown } from 'shared/Dropdown'
import { notesTabState, NotesTabState } from 'core/store/notesTab/notesTab.state'
import { useRecoilState } from 'recoil'
import { Button } from 'shared/Button'
import Icon from 'shared/Icon'
import { CreateJournalNoteModal } from '../../modals/CreateJournalNoteModal/CreateJournalNoteModal'
import { Modal, useModal } from 'shared/Modal'

const VISUAL_VALUE_TEXT = {
  asc: 'From old to the new',
  desc: 'From new to the old',
}
const JOURNAL_TYPE_FILTER_TEXT_MAP = new Map<NotesTabState['noteType'], string>([
  ['JOURNAL_NOTE', 'Only journal notes'],
  ['TRADE_NOTE', 'Only trade notes'],
  [null, 'All note types'],
])

type NotesFiltersProps = { onNotesListUpdate: () => Promise<unknown> }
export function NotesFilters({ onNotesListUpdate }: NotesFiltersProps) {
  const [notesTabStateData, setNotesTabStateData] = useRecoilState(notesTabState)
  const [selectedJournal, setSelectedJournal] = useState<JournalInfo | null>(null)
  const createJournalNoteModal = useModal()

  return (
    <>
      <div className="NotesFilters">
        <div className="filters">
          <JournalFilterDropdown
            selectedJournal={selectedJournal}
            setSelectedJournal={setSelectedJournal}
          />
          <SimpleDropdown
            value={notesTabStateData.noteType}
            onChange={(value) => setNotesTabStateData((prev) => ({ ...prev, noteType: value }))}
            getVisualValue={(value) => JOURNAL_TYPE_FILTER_TEXT_MAP.get(value)}
          >
            <SimpleDropdown.Item value={null} selected>
              All note types
            </SimpleDropdown.Item>
            <SimpleDropdown.Item value="JOURNAL_NOTE">Only journal notes</SimpleDropdown.Item>
            <SimpleDropdown.Item value="TRADE_NOTE">Only trade notes</SimpleDropdown.Item>
          </SimpleDropdown>
        </div>

        <div className="buttons">
          <SimpleDropdown
            className="sorting-dropdown"
            value={notesTabStateData.sortDirection}
            onChange={(value) =>
              setNotesTabStateData((prev) => ({ ...prev, sortDirection: value }))
            }
            getVisualValue={(value) => (
              <SortingDropdownValueVisual>{VISUAL_VALUE_TEXT[value]}</SortingDropdownValueVisual>
            )}
          >
            <SimpleDropdown.Item value="asc">{VISUAL_VALUE_TEXT['asc']}</SimpleDropdown.Item>
            <SimpleDropdown.Item value="desc">{VISUAL_VALUE_TEXT['desc']}</SimpleDropdown.Item>
          </SimpleDropdown>
          <Button color="primary" onClick={() => createJournalNoteModal.open()}>
            <Icon name="Plus" />
            New note
          </Button>
        </div>
      </div>
      <Modal controller={createJournalNoteModal}>
        <CreateJournalNoteModal onUpdate={onNotesListUpdate} controller={createJournalNoteModal} />
      </Modal>
    </>
  )
}

function SortingDropdownValueVisual({ children }: { children: React.ReactNode }) {
  return (
    <div className="SortingDropdownValueVisual">
      <div className="meta color-secondary-l3">
        <Icon name="SortGray" />
        Sort:
      </div>
      {children}
    </div>
  )
}
