import './NoteText.scss'

import classNames from 'classnames'
import { AllHTMLAttributes, useContext, useState, useMemo } from 'react'
import { NoteSectionContext } from '../NoteSectionContext'
import Icon from 'shared/Icon'
import { Button } from 'shared/Button'
import { useCallAndReport } from 'core/hooks/useDoAndReport'
import { editNote } from 'core/api/notes'

type NoteTextProps = { editModeByDefault?: boolean } & AllHTMLAttributes<HTMLDivElement>
export function NoteText({ editModeByDefault, className, ...props }: NoteTextProps) {
  const [editMode, setEditMode] = useState(editModeByDefault ?? false)

  return (
    <section className={classNames('NoteText', className)} {...props}>
      {editMode ? (
        <TradeInfoNoteForm setEditMode={setEditMode} />
      ) : (
        <NoteTextPreview setEditMode={setEditMode} />
      )}
    </section>
  )
}

type NoteTextPreviewProps = { setEditMode: React.Dispatch<React.SetStateAction<boolean>> }
export function NoteTextPreview({ setEditMode }: NoteTextPreviewProps) {
  const { note, editable } = useContext(NoteSectionContext)
  const noteText: string = note?.noteText ?? ''

  return (
    <div
      className={classNames('NoteTextPreview', { editable })}
      onClick={() => editable && setEditMode(true)}
    >
      <div className="text">
        {noteText ? (
          noteText
        ) : (
          <div className="placeholder">
            {editable ? 'Click here to enter note text' : 'This note does not contain any text'}
          </div>
        )}
      </div>
      <div className="side-panel">
        <Icon name="Edit" />
      </div>
    </div>
  )
}

type TradeInfoDialogNoteFormProps = { setEditMode: React.Dispatch<React.SetStateAction<boolean>> }
export function TradeInfoNoteForm({ setEditMode }: Readonly<TradeInfoDialogNoteFormProps>) {
  const { value, setValue, isLoading, submitNote } = useNoteValue(() => setEditMode(false))

  return (
    <div className="TradeInfoNoteForm">
      <textarea
        className={'input'}
        placeholder="Type your note here..."
        rows={7}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />

      <div className="buttons">
        <Button appearance="outline" size="small" onClick={() => setEditMode(false)}>
          Cancel
        </Button>
        <Button color="primary" size="small" isLoading={isLoading} onClick={() => submitNote()}>
          Save
        </Button>
      </div>
    </div>
  )
}

function useNoteValue(onClose: () => void) {
  const { onNoteCreate, onNoteUpdate: onUpdate, note } = useContext(NoteSectionContext)

  const [value, setValue] = useState(note?.noteText ?? '')

  const [initialValue] = useState(note)
  const noteWasChanged: boolean = useMemo(() => {
    if (value === '' && initialValue === undefined) return false
    return value !== initialValue?.noteText
  }, [value, initialValue])

  const { callAndReport, isLoading } = useCallAndReport()
  const submitNote = async () => {
    if (!noteWasChanged) return onClose()
    await callAndReport(
      async () => {
        const noteId = note ? note.id : (await onNoteCreate()).id
        await editNote(value, noteId)
        await onUpdate()
        onClose()
      },
      {
        OK: 'Note updated',
        DEFAULT_ERR: 'Failed to update note',
      }
    )
  }

  return { value, setValue, isLoading, submitNote }
}
