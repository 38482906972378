import './NotesTab.scss'

import { JournalInfo, Note, TradeItem } from 'core/types'
import { useEffect } from 'react'
import { NoteItem } from './components/NoteCard/NoteCard'
import { Modal, useModal } from 'shared/Modal'
import { TradeNoteModal } from './modals/TradeNoteModal/TradeNoteModal'
import { JournalNoteModal } from './modals/JournalNoteModal/JournalNoteModal'
import { NotesFilters } from './components/NotesFilters/NotesFilters'
import { useFilteredNotes } from './hooks'
import { ModalLayout } from 'shared/ModalLayout'
import { Button } from 'shared/Button'
import { useCallAndReport } from 'core/hooks/useDoAndReport'
import { deleteNote } from 'core/api/notes'
import SkeletonBlock from 'shared/SkeletonBlock'
import Pagination from 'shared/Pagination'
import { useRecoilState } from 'recoil'
import { notesTabState as notesTabStateAtom } from 'core/store/notesTab/notesTab.state'
import { filterState } from 'core/store'
import { useUserInfo } from 'core/api/common'
import useJournalsMap from 'core/hooks/useJournalsMap'
import { useSelectedJournalIsLocked } from '../TradesTableTab/hooks'

function usePaginationResetOnJournalChange() {
  const [filters] = useRecoilState(filterState)
  const [, setNotesTabFilters] = useRecoilState(notesTabStateAtom)

  useEffect(() => {
    setNotesTabFilters((st) => ({ ...st, page: 0 }))
  }, [filters.journalId, setNotesTabFilters])
}

// type NotesTabProps = {}
export function NotesTab() {
  usePaginationResetOnJournalChange()
  const response = useFilteredNotes()
  const [notesTabState, setNotesTabFilters] = useRecoilState(notesTabStateAtom)
  const journalsMap = useJournalsMap()

  const tradeNoteModal = useModal<void, { trade: TradeItem; note?: Note; editable: boolean }>()
  const journalNoteModal = useModal<
    void,
    { journal: JournalInfo; note?: Note; editable: boolean }
  >()
  const userInfo = useUserInfo()
  const selectedJournalIsLocked = useSelectedJournalIsLocked()

  const openModal = (note: Note) => {
    const journal = journalsMap.get(note.journalId)
    if (!journal) throw new Error('Journal not found. Cannot open note modal')
    const editable = userInfo.data?.userEmail === journal.owner.userEmail

    if (note.relatedTrade) {
      tradeNoteModal.open({ trade: note.relatedTrade, note, editable })
    } else {
      journalNoteModal.open({ journal, note, editable })
    }
  }

  const confirmRemoveNoteModal = useModal<boolean>()
  const { isLoading, callAndReport } = useCallAndReport()
  const startDeletingNote = async (note: Note) => {
    const shouldDelete = await confirmRemoveNoteModal.open()
    if (!shouldDelete) return
    await callAndReport(
      async () => {
        await deleteNote(note.id)
        await response.mutate()
      },
      { OK: 'Note removed successfully' }
    )
    confirmRemoveNoteModal.close()
  }

  return (
    <div className="NotesTab">
      <NotesFilters onNotesListUpdate={response.mutate} />
      <div className="notes">
        <div className="notes-list">
          {response.isLoading && <NotesSkeleton />}
          {response.data?.result.map((note: Note) => (
            <NoteItem
              key={note.id}
              journal={journalsMap.get(note.journalId)}
              note={note}
              editable={!selectedJournalIsLocked}
              onClick={() => openModal(note)}
              onDelete={() => startDeletingNote(note)}
            />
          ))}
          {response.data?.result.length === 0 && 'No notes found that match the filters'}

          {response.isLoading || (
            <Pagination
              currentPageIndex={notesTabState.page}
              pagesTotal={response.data?.pageInfo.totalPages || 0}
              requestPage={(page) => setNotesTabFilters((st) => ({ ...st, page }))}
            />
          )}
        </div>
      </div>

      <Modal controller={tradeNoteModal}>
        <TradeNoteModal controller={tradeNoteModal} onUpdate={response.mutate} />
      </Modal>
      <Modal controller={journalNoteModal}>
        <JournalNoteModal controller={journalNoteModal} onUpdate={response.mutate} />
      </Modal>
      <Modal controller={confirmRemoveNoteModal}>
        <ModalLayout>
          <ModalLayout.Heading>Remove note</ModalLayout.Heading>
          <ModalLayout.Description>
            Are you sure you want to remove this note?
          </ModalLayout.Description>
          <ModalLayout.Buttons>
            <Button onClick={() => confirmRemoveNoteModal.close(false)}>Cancel</Button>
            <Button
              color="error"
              isLoading={isLoading}
              onClick={() => {
                confirmRemoveNoteModal.resolveWithoutClosing(true)
              }}
            >
              Remove
            </Button>
          </ModalLayout.Buttons>
        </ModalLayout>
      </Modal>
    </div>
  )
}

function NotesSkeleton() {
  const dummies = Array.from({ length: 5 }).map((_, i) => i)
  return (
    <>
      {dummies.map((i) => (
        <SkeletonBlock key={i} className="note-skeleton" />
      ))}
    </>
  )
}
