import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { API_URL } from 'core/constants'
import { userManager } from './httpClient'

const httpLink = new HttpLink({ uri: API_URL + '/api/dashboard/widgets/graphql' })

const authLink = setContext(async ({ operationName }, { headers }) => {
  const token = (await userManager.getUser())?.access_token

  return {
    headers: {
      ...headers,
      ...(token && { authorization: `Bearer ${token}` }),
    },
  }
})

export const graphQlClient = new ApolloClient({
  link: from([/*errorLink, */ authLink, httpLink]),
  cache: new InMemoryCache(),
})
