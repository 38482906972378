import './Login.scss'
import logo from 'assets/trading-journal-logo.svg'
import { ModalLayout } from 'shared/ModalLayout'
import { Button } from 'shared/Button'
import { useAuth } from 'react-oidc-context'

export function Login() {
  const auth = useAuth()

  if (auth.error) {
    return (
      <div className="Login card-page">
        <ModalLayout>
          <ModalLayout.Heading>Something went wrong...</ModalLayout.Heading>
          <ModalLayout.Description>{auth.error.message}</ModalLayout.Description>
        </ModalLayout>
      </div>
    )
  }

  return (
    <div className="Login card-page">
      <ModalLayout>
        <img className="logo" src={logo} alt="Logo" />
        <ModalLayout.Description>
          You need to login in order to use Trading Journal
        </ModalLayout.Description>
        <ModalLayout.Buttons singleButton>
          <Button color="primary" onClick={() => auth.signinRedirect()} isLoading={auth.isLoading}>
            Login
          </Button>
        </ModalLayout.Buttons>
      </ModalLayout>
    </div>
  )
}
