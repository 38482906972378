import './ModalWindowLayout.scss'
import classNames from 'classnames'
import { AllHTMLAttributes } from 'react'
import { Button } from 'shared/Button'
import Icon from 'shared/Icon'

type ModalWindowLayoutProps = { children?: React.ReactNode } & AllHTMLAttributes<HTMLDivElement>
function ModalWindowLayout({ children, className, ...props }: Readonly<ModalWindowLayoutProps>) {
  return (
    <div className={classNames('ModalWindowLayout', className ? className : '')} {...props}>
      {children}
    </div>
  )
}

type ModalWindowLayoutHeaderProps = {
  children?: React.ReactNode
  onClose: () => void
} & AllHTMLAttributes<HTMLDivElement>
function ModalWindowLayoutHeader({
  children,
  onClose,
  className,
  ...props
}: Readonly<ModalWindowLayoutHeaderProps>) {
  return (
    <div className={classNames('ModalWindowLayoutHeader', className ? className : '')} {...props}>
      <div className="label text-big-bold">{children}</div>
      <Button appearance="text" size="small" square onClick={() => onClose()}>
        <Icon name="Cross" />
      </Button>
    </div>
  )
}

type ModalWindowLayoutContentProps = {
  children?: React.ReactNode
} & AllHTMLAttributes<HTMLDivElement>
function ModalWindowLayoutContent({
  children,
  className,
  ...props
}: Readonly<ModalWindowLayoutContentProps>) {
  return (
    <div className={classNames('ModalWindowLayoutContent', className ? className : '')} {...props}>
      {children}
    </div>
  )
}

type ModalWindowLayoutFooterProps = {
  children?: React.ReactNode
  wide?: boolean
} & AllHTMLAttributes<HTMLDivElement>
function ModalWindowLayoutFooter({
  children,
  className,
  wide,
  ...props
}: Readonly<ModalWindowLayoutFooterProps>) {
  return (
    <div
      className={classNames('ModalWindowLayoutFooter', className ? className : '', { wide })}
      {...props}
    >
      {children}
    </div>
  )
}

ModalWindowLayout.Header = ModalWindowLayoutHeader
ModalWindowLayout.Content = ModalWindowLayoutContent
ModalWindowLayout.Footer = ModalWindowLayoutFooter

export default ModalWindowLayout
