import { IconsSrc } from 'core/assets'
import './ModalLayout.scss'
import { AllHTMLAttributes } from 'react'
import { Button, ButtonProps } from 'shared/Button'
import { Tooltip } from 'shared/Tooltip'
import classNames from 'classnames'

type ModalLayoutProps = { children?: React.ReactNode } & AllHTMLAttributes<HTMLDivElement>
function ModalLayout({ children, className, ...props }: Readonly<ModalLayoutProps>) {
  return (
    <div className={'ModalLayout ' + className} {...props}>
      {children}
    </div>
  )
}

type ModalLayoutCloseButtonProps = ButtonProps
function ModalLayoutCloseButton(props: Readonly<ModalLayoutCloseButtonProps>) {
  return (
    <Button appearance="text" square className="ModalLayoutCloseButton" {...props}>
      <img src={IconsSrc.Cross} alt="Close" />
    </Button>
  )
}

type ModalLayoutHeadingProps = {
  children?: React.ReactNode
} & AllHTMLAttributes<HTMLDivElement>
function ModalLayoutHeading({ children, ...props }: Readonly<ModalLayoutHeadingProps>) {
  return (
    <div className="ModalLayoutHeading" {...props}>
      {children}
    </div>
  )
}

type ModalLayoutDescriptionProps = { children: React.ReactNode } & AllHTMLAttributes<HTMLDivElement>
function ModalLayoutDescription({ children, ...props }: Readonly<ModalLayoutDescriptionProps>) {
  return (
    <div className="ModalLayoutDescription" {...props}>
      {children}
    </div>
  )
}

type ModalLayoutButtonsProps = {
  children?: React.ReactNode
  singleButton?: boolean
} & AllHTMLAttributes<HTMLDivElement>
function ModalLayoutButtons({
  children,
  className,
  singleButton,
  ...props
}: Readonly<ModalLayoutButtonsProps>) {
  return (
    <div className={classNames('ModalLayoutButtons', className, { singleButton })} {...props}>
      {children}
    </div>
  )
}

type ModalLayoutFormControlProps = AllHTMLAttributes<HTMLDivElement> & {
  labelElement?: React.ReactNode
  children?: React.ReactNode
  isError?: boolean
  required?: boolean
}
function ModalLayoutFormControl({
  children,
  label,
  required,
  labelElement,
  className,
  isError,
  ...props
}: Readonly<ModalLayoutFormControlProps>) {
  return (
    <div
      className={'ModalLayoutFormControl ' + (className ?? '') + (isError ? 'error' : '')}
      {...props}
    >
      {label && (
        <div className="ModalLayoutFormControl__label">
          {label}
          {required && (
            <Tooltip tooltipContent="Required">
              <span className="required-mark"> *</span>
            </Tooltip>
          )}
        </div>
      )}
      {labelElement && <div className="ModalLayoutFormControl__label">{labelElement}</div>}
      {children}
    </div>
  )
}

type ModalLayoutFormControlErrorProps = {
  children: React.ReactNode
} & AllHTMLAttributes<HTMLDivElement>
function ModalLayoutFormControlError({
  children,
  ...props
}: Readonly<ModalLayoutFormControlErrorProps>) {
  return (
    <div className="ModalLayoutFormControlError" {...props}>
      {children}
    </div>
  )
}
ModalLayoutFormControl.Error = ModalLayoutFormControlError

ModalLayout.CloseButton = ModalLayoutCloseButton
ModalLayout.Heading = ModalLayoutHeading
ModalLayout.Description = ModalLayoutDescription
ModalLayout.Buttons = ModalLayoutButtons
ModalLayout.FormControl = ModalLayoutFormControl
export default ModalLayout
