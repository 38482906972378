import { createPortal } from 'react-dom'
import { ModalController } from './models'
import './Modal.scss'
import { useRef } from 'react'
import classNames from 'classnames'

type ModalProps = {
  children?: React.ReactNode
  controller: ModalController<any, any>
  position?: 'start' | 'center' | 'end'
  stopPropagation?: boolean
}
export const Modal = ({
  children,
  controller,
  position = 'center',
  stopPropagation,
}: ModalProps) => {
  const overlayRef = useRef<HTMLDivElement>(null)
  if (!controller.isOpen) return null

  const handleClick = async (e: React.MouseEvent<HTMLDivElement>) => {
    if (stopPropagation) e.stopPropagation()
    if (!overlayRef.current) return
    if (e.target !== overlayRef.current) return
    console.log('backdropClickHandler', controller.backdropClickHandler)
    const shouldClose = await controller.backdropClickHandler()
    if (shouldClose) controller.close()
  }
  return createPortal(
    <div
      ref={overlayRef}
      className={classNames('Modal__overlay', `position-${[position]}`)}
      onClick={handleClick}
    >
      <div className="Modal">{children}</div>
    </div>,
    document.body
  )
}
