import { AllHTMLAttributes, useEffect, useMemo, useState } from 'react'
import './DateInput.scss'
import Calendar from 'react-calendar'
import { Value } from 'react-calendar/dist/cjs/shared/types'
import Floating from 'shared/Floating'
import classNames from 'classnames'
import { useGlobalTimezone } from 'core/contexts/GlobalTimezoneContext'
import { DateInputTrigger } from './components/DateInputTrigger'
import { DateInputTimePicker } from './components/DateInputTimePicker'
import { useTimePickerAutoscroll } from './useTimePickerAutoscroll'
import { getDatesToPick, getInitialTime } from './utils'
import { Button } from 'shared/Button'
import { useCombinedDate } from './hooks'

type DateInputProps = Omit<AllHTMLAttributes<HTMLDivElement>, 'value' | 'onChange'> & {
  value?: Date
  onChange?: (value: Date) => void
}
export function DateInput({
  value = getInitialTime(),
  onChange = () => {},
  className,
  ...rest
}: Readonly<DateInputProps>) {
  const [open, setOpen] = useState(false)
  const { baseDate, setBaseDate, baseTime, setBaseTime, combinedDate, setCombinedDate, today } =
    useCombinedDate()

  const { timeZone } = useGlobalTimezone()
  const timePickerDates = useMemo(() => {
    const newPickDates = getDatesToPick(timeZone.offsetMsRelative, baseDate)
    return newPickDates
  }, [timeZone, baseDate])

  const { timePickerRef } = useTimePickerAutoscroll(open)

  useEffect(() => setCombinedDate(value), [setCombinedDate, value])

  const apply = () => {
    onChange(combinedDate)
    setOpen(false)
  }
  const updateBaseDate = (value: Value) => value instanceof Date && setBaseDate(value)

  return (
    <div className={classNames('DateInput', className)} {...rest}>
      <Floating
        opened={open}
        setOpened={(opened) => setOpen(opened)}
        closeOnClickInside={false}
        trigger={<DateInputTrigger open={open} value={combinedDate} />}
        content={
          <div className="DateInput__content">
            <div className="DateInput__content__pickers-wrapper">
              <Calendar
                className="DateInput__content__calendar"
                value={baseDate}
                onChange={updateBaseDate}
                minDetail={'month'}
              />
              <DateInputTimePicker
                ref={timePickerRef}
                value={baseTime}
                pickerDates={timePickerDates}
                onChange={setBaseTime}
              />
            </div>
            <div className="DateInput__content__controls">
              <Button
                appearance="text"
                className="text-button"
                size="small"
                onClick={() => today()}
              >
                Today
              </Button>
              <Button
                appearance="text"
                color="primary"
                size="small"
                className="link-button"
                onClick={() => apply()}
                disabled={baseTime.getTime() === value.getTime()}
              >
                Apply
              </Button>
            </div>
          </div>
        }
      />
    </div>
  )
}
