import { useCallback, useEffect, useMemo, useState } from 'react'
import { GlobalTimezoneContextData, TimezoneItem } from './models'
import { getInitialTimezoneItem } from './utils'
import { INITIAL_FORMATTING_OPTIONS, TIMEZONE_LS_TOKEN, TIMEZONE_OFFSET_LIST } from './constants'
import { GlobalTimezoneContext } from './GlobalTimezoneContext'

export function GlobalTimezoneContextProvider({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  const [timeZone, setTimeZone] = useState<TimezoneItem>(() => getInitialTimezoneItem())

  const getDateTimeFormatter = useCallback(
    (options: Intl.DateTimeFormatOptions) =>
      new Intl.DateTimeFormat(INITIAL_FORMATTING_OPTIONS.locale, {
        // new Intl.DateTimeFormat(undefined, {
        ...options,
        timeZone: timeZone.pickTimezone,
      }),

    [timeZone]
  )
  const [dateFormatter, timeFormatter] = useMemo(
    () => [
      getDateTimeFormatter({ year: 'numeric', month: 'numeric', day: 'numeric' }),
      getDateTimeFormatter({ hour: 'numeric', minute: 'numeric', second: 'numeric' }),
    ],
    [getDateTimeFormatter]
  )

  useEffect(() => {
    localStorage.setItem(TIMEZONE_LS_TOKEN, JSON.stringify(timeZone))
  }, [timeZone])

  const providerValue = useMemo(
    () =>
      ({
        timeZone,
        setTimeZone,
        availableTimeZonesNameOptions: TIMEZONE_OFFSET_LIST,
        dateFormatter,
        timeFormatter,
        getDateTimeFormatter,
      } satisfies GlobalTimezoneContextData),
    [timeZone, setTimeZone, dateFormatter, timeFormatter, getDateTimeFormatter]
  )

  return (
    <GlobalTimezoneContext.Provider value={providerValue}>
      {children}
    </GlobalTimezoneContext.Provider>
  )
}
