import { Note, TradeItem } from 'core/types'
import { TradeInfoGridFull } from 'pages/HomePage/tabs/NotesTab/modals/TradeNoteModal/TradeInfoGridFull/TradeInfoGridFull'
import { ModalController } from 'shared/Modal'
import ModalWindowLayout from 'shared/ModalWindowLayout'
import { NoteSection } from '../../components/NoteSection/NoteSection'
import { createTradeNote, getNote } from 'core/api/notes'
import { useCallback, useRef, useState } from 'react'
import { Button } from 'shared/Button'

type TradeNoteModalProps = {
  controller: ModalController<
    void,
    {
      trade: TradeItem
      note?: Note
      editable: boolean
    }
  >
  onUpdate: () => Promise<unknown>
}
export function TradeNoteModal({ controller, onUpdate }: TradeNoteModalProps) {
  if (!controller.data) throw new Error('TradeNoteModal: no data provided')
  const { trade, note } = controller.data

  const [updatedNote, setUpdatedNote] = useState<Note | null>(null)
  const updatedNoteRef = useRef<Note | null>(null)

  const createNote = useCreateNote({
    updateNote: useCallback(
      (note) => {
        setUpdatedNote(note)
        updatedNoteRef.current = note
      },
      [setUpdatedNote]
    ),
    tradeId: trade.id,
  })

  const onUpdated = useCallback(async () => {
    console.log('onUpdated')
    const currentNote = updatedNoteRef.current ?? updatedNote ?? note
    if (!currentNote) throw new Error('No note provided')
    const response = await getNote(currentNote.id)
    setUpdatedNote(response.data.result)
    return await onUpdate()
  }, [note, onUpdate, updatedNote])

  // console.log(trade, note)
  return (
    <ModalWindowLayout>
      <ModalWindowLayout.Header onClose={() => controller.close()}>
        Trade Note
      </ModalWindowLayout.Header>
      <ModalWindowLayout.Content>
        <TradeInfoGridFull tradeItem={trade} />
        <NoteSection
          note={updatedNote ?? note}
          onNoteCreate={createNote}
          onNoteUpdate={onUpdated}
          editable={controller.data.editable}
        />
      </ModalWindowLayout.Content>
      <ModalWindowLayout.Footer>
        <Button onClick={() => controller.close()}>OK</Button>
      </ModalWindowLayout.Footer>
    </ModalWindowLayout>
  )
}

type useCreateNoteParams = {
  updateNote: (note: Note) => void
  tradeId: string
}
function useCreateNote({ updateNote, tradeId }: useCreateNoteParams) {
  const createNotePromiseRef = useRef<Promise<Note> | null>(null)

  const actuallyCreateNote = useCallback(async () => {
    const response = await createTradeNote(tradeId)
    const note: Note = response.data.result
    updateNote(note)
    return note
  }, [tradeId, updateNote])

  const requestToCreateNote = useCallback(async () => {
    if (createNotePromiseRef.current) return createNotePromiseRef.current
    const promise = actuallyCreateNote()
    createNotePromiseRef.current = promise
    return promise
  }, [actuallyCreateNote])

  return requestToCreateNote
}
