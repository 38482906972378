import { useJournals, useJournalsSharedWithMe } from 'core/api/journals'
import { JournalInfo } from 'core/types'
import { useMemo } from 'react'

export default function useJournalsMap() {
  const journals = useJournals()
  const sharedJournals = useJournalsSharedWithMe()
  const journalsMap = useMemo(() => {
    const map = new Map<string, JournalInfo>()
    journals.data?.forEach((journal) => map.set(journal.id, journal))
    sharedJournals.data?.forEach((journal) => map.set(journal.id, journal))
    return map
  }, [journals.data, sharedJournals.data])
  return journalsMap
}
