import './App.scss'
import { Outlet } from 'react-router-dom'
import { Notifications } from 'shared/Notifications'
import { GlobalTimezoneContextProvider } from 'core/contexts/GlobalTimezoneContext'
import { JournalsApiContextProvider } from 'core/contexts/JournalsApiContext'
import { SWRConfig } from 'swr'
import { ApolloProvider } from '@apollo/client'
import { graphQlClient } from 'core/api/graphQlClient'
import { useAuth } from 'react-oidc-context'

function App() {
  const auth = useAuth()

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <div>Signing you in...</div>
    case 'signoutRedirect':
      return <div>Signing you out...</div>
  }

  return (
    <div className="App">
      <Outlet />
    </div>
  )
}

function AppWithProviders() {
  return (
    <SWRConfig value={{ revalidateOnMount: true }}>
      <ApolloProvider client={graphQlClient}>
        <GlobalTimezoneContextProvider>
          <Notifications>
            <JournalsApiContextProvider>
              <App />
            </JournalsApiContextProvider>
          </Notifications>
        </GlobalTimezoneContextProvider>
      </ApolloProvider>
    </SWRConfig>
  )
}

export default AppWithProviders
