import { AtomEffect } from 'recoil'
import { TradesTableState } from './tradesTable.state'

export const localStorageEffect =
  (key: string): AtomEffect<TradesTableState> =>
  ({ setSelf, onSet }) => {
    /* ON INIT */
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      try {
        const parsedValue = JSON.parse(savedValue) as TradesTableState
        parsedValue.page = 0
        setSelf(parsedValue)
      } catch (e) {
        console.error('Failed to parse saved value', e)
      }
    }

    /* ON CHANGE */
    onSet((newValue, _, isReset) => {
      if (isReset) {
        localStorage.removeItem(key)
      } else {
        localStorage.setItem(key, JSON.stringify(newValue))
      }
    })
  }
