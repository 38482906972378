import './NoteSection.scss'

import { Note } from 'core/types'
import { NoteText } from './NoteText/NoteText'
import { NoteMedia } from './NoteMedia/NoteMedia'
import { NoteSectionContextProvider } from './NoteSectionContext'
import classNames from 'classnames'
import TextDate from 'shared/TextDate'

type NoteSectionProps = {
  note?: Note
  disabled?: boolean
  altNoteTextArea?: React.ReactNode
  editable?: boolean
  onNoteCreate: () => Promise<Note>
  onNoteUpdate: () => Promise<unknown>
}
export function NoteSection({
  note,
  onNoteCreate,
  onNoteUpdate,
  editable,
  disabled,
  altNoteTextArea,
}: NoteSectionProps) {
  return (
    <NoteSectionContextProvider
      note={note}
      onNoteCreate={onNoteCreate}
      onNoteUpdate={onNoteUpdate}
      editable={editable}
    >
      <div className={classNames('NoteSection', { disabled })}>
        <div className="header">
          <div className="header text-normal-bold">Note</div>
          {note && (
            <div className="color-secondary-l3">
              Created <TextDate boldDate={false} date={new Date(note.creationDate)} />
            </div>
          )}
        </div>
        {altNoteTextArea ? altNoteTextArea : <NoteText />}
        <NoteMedia />
      </div>
    </NoteSectionContextProvider>
  )
}
