import { MediaItem, Note } from 'core/types'
import httpClient from './httpClient'
import { API_URL } from 'core/constants'
import { AxiosProgressEvent } from 'axios'
import { httpClientFetcher } from './httpClientFetcher'
import useSWR from 'swr'
import { useMemo } from 'react'

type UseNotesParams = {
  journalIds?: string[]
  includeTradeNotes?: boolean
  includeJournalNotes?: boolean
  page?: number
  size?: number
  sort?: keyof Note
  sortDirection?: 'asc' | 'desc'
}
type UseNotesResponse = {
  result: Note[]
  pageInfo: {
    totalPages: number
    totalElements: number
  }
}
export const useNotes = (params: UseNotesParams) => {
  const finalUrl = useMemo(() => {
    const { journalIds, includeTradeNotes, includeJournalNotes, page, size, sort, sortDirection } =
      params
    const url = new URL(API_URL + '/api/dashboard/journals/notes')

    if (includeJournalNotes !== undefined)
      url.searchParams.set('includeJournalNotes', includeJournalNotes.toString())
    if (includeTradeNotes !== undefined)
      url.searchParams.set('includeTradeNotes', includeTradeNotes.toString())
    if (journalIds && journalIds.length) url.searchParams.set('journalIds', journalIds.join(','))
    if (page) url.searchParams.set('page', page.toString())
    if (size) url.searchParams.set('size', size.toString())
    if (sort) {
      if (sortDirection) {
        url.searchParams.set('sort', `${sort},${sortDirection}`)
      } else {
        url.searchParams.set('sort', sort)
      }
    }

    return url.toString()
  }, [params])

  return useSWR(finalUrl, httpClientFetcher<UseNotesResponse>)
}

export const getNote = async (noteId: string) => {
  return httpClient.get<{ result: Note }>(API_URL + `/api/dashboard/notes/${noteId}`)
}
export const useNote = (id?: string | null) => {
  return useSWR(
    id != null ? API_URL + `/api/dashboard/notes/${id}` : null,
    httpClientFetcher<{ result: Note }>,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )
}

// CRUD operations for trade notes
export const createTradeNote = async (tradeId: string) => {
  console.log('[API]: create trade note for trade with id =', tradeId)
  return httpClient.post<{ result: Note }>(API_URL + `/api/dashboard/notes/trade/${tradeId}`)
}

export const createJournalNote = async (journalId: string) => {
  return httpClient.post<{ result: Note }>(API_URL + `/api/dashboard/notes/journal/${journalId}`)
}

export const moveJournalNoteToJournal = async (noteId: string, journalId: string) => {
  return httpClient.put<{ result: Note }>(
    API_URL + `/api/dashboard/notes/${noteId}/moveTo?journalId=${journalId}`
  )
}

export const editNote = async (noteText: string, noteId: string) => {
  console.log('[API]: edit note with id =', noteId, 'to text =', noteText)
  return httpClient.put(API_URL + `/api/dashboard/notes/${noteId}`, { noteText })
}

export const deleteNote = async (noteId: string) => {
  return httpClient.delete<void>(API_URL + `/api/dashboard/notes/${noteId}`)
}

export const markNoteAsCreated = async (noteId: string) => {
  return httpClient.post<{ result: Note }>(API_URL + `/api/dashboard/notes/${noteId}`)
}

// CRUD operations for trade notes media
export const addMediaToNote = async (
  noteId: string,
  media: File,
  onUploadProgress?: (event: AxiosProgressEvent) => void
): Promise<any> => {
  console.log('[API]: add media to note', { noteId, media })
  const formData = new FormData()
  formData.append('file', media)
  return httpClient.post(API_URL + `/api/dashboard/notes/${noteId}/media`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress,
  })
}

const getMediaForNoteUrl = (noteId: string) => API_URL + `/api/dashboard/notes/${noteId}/media`
export const getMediaForNote = async (noteId: string) => {
  return httpClient.get(getMediaForNoteUrl(noteId))
}
export const useMediaForNote = (noteId?: string | null) => {
  return useSWR(
    noteId ? getMediaForNoteUrl(noteId) : null,
    httpClientFetcher<{ result: MediaItem[] }>,
    { revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false }
  )
}

export const deleteMediaForNote = async (noteId: string, mediaKey: string) => {
  return httpClient.delete(
    API_URL + `/api/dashboard/notes/${noteId}/media?key=${encodeURI(mediaKey)}`
  )
}
