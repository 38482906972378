import axios from 'axios'
import { OAUTH_CONFIG } from 'core/configs/auth'
import { UserManager } from 'oidc-client-ts'

const httpClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
})

export const userManager = new UserManager(OAUTH_CONFIG)

httpClient.interceptors.request.use(async (config) => {
  const token = (await userManager.getUser())?.access_token
  if (token) config.headers.Authorization = `Bearer ${token}`
  return config
})

httpClient.interceptors.response.use(
  (response) => response
  // (error) => {
  //   if (error.response.status === 401) {
  //     localStorage.removeItem('token')
  //     localStorage.removeItem(LS_ACCESS_TOKEN)
  //     if (window.location.pathname !== '/login' && window.location.pathname !== '/signup') {
  //       window.location.reload()
  //     }
  //   }
  //   return Promise.reject(error)
  // }
)

export default httpClient
