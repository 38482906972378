import { ChartSection } from './sections/ChartSection/ChartSection'
import './DashboardTab.scss'
import { StatisticsSection } from './sections/StatisticsSection/StatisticsSection'
import WidgetsSection from './sections/WidgetsSection'
import Filters from 'pages/HomePage/components/Filters'

export function DashboardTab() {
  return (
    <div className="DashboardTab">
      <Filters />
      <StatisticsSection />
      <ChartSection label="P&L history" />
      <WidgetsSection />
    </div>
  )
}
