import { PagingParams } from 'core/api/dashboard'
import { Note } from 'core/types'
import { atom } from 'recoil'
import { notesTabLocalStorageEffect } from './notesTab.effects'

export type NotesTabState = PagingParams & {
  noteType: 'JOURNAL_NOTE' | 'TRADE_NOTE' | null
  sort: keyof Note
  sortDirection: 'asc' | 'desc'
}

export const notesTabState = atom<NotesTabState>({
  key: 'notesTabState',
  default: {
    page: 1,
    size: 10,
    sort: 'creationDate',
    sortDirection: 'desc',
    noteType: null,
  },
  effects: [notesTabLocalStorageEffect('notesTabState')],
})
