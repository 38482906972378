import { useNotes } from 'core/api/notes'
import { filterState } from 'core/store'
import { notesTabState as notesTabStateAtom } from 'core/store/notesTab/notesTab.state'
import { useRecoilState } from 'recoil'

export function useFilteredNotes() {
  const [filters] = useRecoilState(filterState)
  const [notesTabState] = useRecoilState(notesTabStateAtom)
  return useNotes({
    journalIds: filters.journalId ? [filters.journalId] : [],
    includeJournalNotes:
      notesTabState.noteType === 'JOURNAL_NOTE' || notesTabState.noteType === null,
    includeTradeNotes: notesTabState.noteType === 'TRADE_NOTE' || notesTabState.noteType === null,
    sort: notesTabState.sort,
    sortDirection: notesTabState.sortDirection,
    size: 10,
    page: notesTabState.page,
  })
}
